<template>
  <div>
    <b-alert variant="danger" :show="maxWeight == 0">
      Não será possivel adicionar novos indicadores, pois a somatória dos
      indicadores cadastrados já atingiu o valor máximo 10. Considere
      redistribuir os pesos dos indicadores
    </b-alert>
    <div v-if ="Object.keys(template.indicator.result_source).length === 0 && !is_add" class="text-center">
      <b-spinner class="mr-2" />
    </div>
    <form v-else :form="template" @submit.prevent="handleSaveTemplate()">
      <div
        class="w-100 d-block d-md-flex align-items-center justify-content-between flex-md-row"
      >
        <b-form-group label="Nome do indicador" class="w-100 mr-2">
          <b-form-input
            autofocus
            :class="$v.template.indicator.name.$error && 'is-invalid'"
            class="mt-1"
            :disabled="is_provider"
            v-model="template.indicator.name"
          />
          <b-form-invalid-feedback v-if="!$v.template.indicator.name.required">
            {{ $t("auth.type-valid-name") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Fonte de pesquisa" class="w-100 mr-2">
          <multiselect
            class="mt-1"
            :disabled="is_provider"
            :class="$v.template.indicator.result_source.$error && 'is-invalid'"
            track-by="name"
            label="name"
            :allow-empty="false"
            v-model="template.indicator.result_source"
            :show-labels="false"
            :options="sourceOptions"
            @select="handleSelectResultSource"
            placeholder="Selecione a fonte de pesquisa"
          />
          <b-form-invalid-feedback
            v-if="$v.template.indicator.result_source.$error"
          >
            Selecione uma fonte de pesquisa
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Validação" class="w-100">
          <multiselect
            class="mt-1"
            :class="$v.template.indicator.type.$error && 'is-invalid'"
            :disabled="
              is_provider ||
                !(
                  template.indicator.result_source.result_source ||
                  template.indicator.result_source.id
                )
            "
            v-model="template.indicator.type"
            :show-labels="false"
            :allow-empty="false"
            :options="typeOptions"
            label="name"
            @input="handleChangetype"
            placeholder="Selecione o tipo do campo"
          />
          <b-form-invalid-feedback v-if="$v.template.indicator.type.$error">
            Selecione o tipo de validação
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="mt-3">
        <div class="d-flex align-items-center justify-content-between">
          <section class="w-100">
            <section class="auto-track">
              <div class="border-light form animated fadeIn faster">
                <section class="authomatization">
                  <div class="mb-2 d-flex flex-wrap gap-3">
                    <b-form-group
                      class="col-md-2 px-0"
                      label="Quantidade de faixas"
                    >
                      <multiselect
                        :disabled="isEditableField(template)"
                        v-model="template.emptyRanges"
                        :show-labels="false"
                        :allow-empty="false"
                        :options="rangeOptions"
                        @input="handleAddRanges(template)"
                        placeholder="Selecione a quantidade de faixas"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          <span>{{ option }} faixas</span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <span>{{ props.option }} faixas</span>
                        </template>
                      </multiselect>
                    </b-form-group>
                    <b-form-group label="Valor mínimo" class="col-md-2 px-0">
                      <b-form-input
                        :disabled="isEditableField(template)"
                        v-mask="mask"
                        v-model="template.minValue"
                        @change="handleChangeConfigRange(template)"
                        autocomplete="off"
                      />
                    </b-form-group>
                    <b-form-group label="Intervalo" class="col-md-2 px-0">
                      <b-form-input
                        :disabled="isEditableField(template)"
                        v-mask="mask"
                        v-model="template.interval"
                        @change="handleChangeConfigRange(template)"
                        autocomplete="off"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Inverter pontuação"
                      class="col-md-3 px-0"
                    >
                      <b-form-checkbox
                        :disabled="template.emptyRanges < 2"
                        class="p-0 mr-2 mt-2"
                        @change="handleChangeConfigRange(template)"
                        v-model="template.isDecrescent"
                      >
                        Valor decrescente
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                  <small class="d-block text-primary mt-3">
                    Alterando essas configurações os valores da faixa serão
                    recalculados
                  </small>
                </section>
                <section class="d-flex align-items-end w-100">
                  <b-form-group
                    :label="`Peso do indicador ${template.weight}`"
                    class="w-100"
                  >
                    <b-form-input
                      :disabled="maxWeight == 0"
                      type="range"
                      :max="maxWeight"
                      step="0.1"
                      min="0.1"
                      v-model="template.weight"
                      @change="handleChangeConfigRange(template)"
                      autocomplete="off"
                    />
                    <b-form-invalid-feedback v-if="maxWeight == 0">
                      A senha deve conter no mínimo 6 caracteres
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <h5 class="mb-3 ml-3 text-nowrap">
                    {{ template.weight }}
                  </h5>
                </section>
              </div>
            </section>
            <Ranges
              v-disable="maxWeight == 0"
              class="mt-4"
              :type="template.indicator.type.id"
              v-if="template.ranges"
              :tracks="template.ranges"
              :minValue="template.minValue"
              :interval="template.interval"
              @change_track="changedRanges"
            />
          </section>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end mt-4">
        <b-button
          variant="light"
          class="mr-2"
          @click="$emit('close_modal_template')"
        >
          Cancelar
        </b-button>
        <b-button variant="primary" type="submit" :disabled="maxWeight == 0">
          Salvar
        </b-button>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { isNumber } from "@/utils/validations/isNumber.js";
import Ranges from "./Ranges";
import { required } from "vuelidate/lib/validators";
import { api } from "@/services/api";
import { validationMixin } from "vuelidate";

const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
});

export default {
  mixins: [validationMixin],

  components: {
    Ranges,
  },
  props: {
    selected_module: {
      type: Object,
    },
    selected_submodule: {
      type: Object,
    },
    selected_id: {
      type: [String, Number],
    },
    is_add: {
      type: Boolean,
    },
  },
  validations: {
    template: {
      indicator: {
        name: {
          required,
        },
        result_source: {
          required,
        },
        type: {
          required,
        },
      },
    },
  },
  data() {
    return {
      maxWeight: 10,
      template_id: "",
      templates: [],
      indicatorsOptions: [],
      sourceOptions: [],
      rangeOptions: Array.from(Array(12).keys()).filter((item) => item > 1),
      typeOptions: [
        { id: "percentage", name: "Percentual" },
        { id: "money", name: "Valor monetário" },
        { id: "value", name: "Valor simples" },
        { id: "yes_no", name: "Sim ou Não" },
        { id: "include", name: "Inclui valor na listagem" },
        { id: "manual", name: "Validação manual" },
      ],
      mask: currencyMask,
      template: {
        sourceOptions: [],
        ranges: [],
        weight: 0,
        result_source: {},
        indicator: {
          name: "",
          result_source: {},
          type: {},
        },
      },
      hasInvalidRange: false,
      isNumber,
    };
  },
  computed: {
    ...mapGetters([
      "is_provider",
      "template_indicators",
      "current_template_indicator",
    ]),
  },
  methods: {
    ...mapActions([
      "get_template_indicator",
      "add_template_indicator",
      "edit_template_indicator",
      "show_toast"
    ]),

    isEditableField(template) {
      return (
        template.indicator.type.id === "yes_no" ||
        template.indicator.type.id === "include"
      );
    },

    handleChangetype(event) {
      if (event.id === "yes_no" || event.id === "include") {
        this.template = {
          ...this.template,
          renges: [
            {
              value: 0,
              min: 0,
              max: 1,
            },
            {
              value: 10,
              min: 1,
              max: 2,
            },
          ],
          emptyRanges: 2,
        };

        this.handleAddRanges(this.template);
      }
    },

    completeObject(template) {
      if (!template?.ranges.length) {
        template.ranges = [];
      }
    },

    handleSave(form) {
      const payload = {
        name: form.indicator.name,
        result_source: form.indicator.result_source.id
          ? Number(form.indicator.result_source.id)
          : Number(form.indicator.result_source.result_source),
        argument: form.indicator.result_source.argument,
        type: form.indicator.type.id,
        ranges: form.ranges,
        weight: Number(this.template.weight),
        template: Number(this.template_id),
        sub_module: this.selected_submodule.code,
      };

      if (Number(this.template.weight) == 0){
        this.show_toast({
          message: "Por favor, defina o peso do indicador",
          type: "error",
        });
        return;
      }

      if (form.id) {
        return this.editTemplateIndicator({ ...payload, id: form.id });
      }
      return this.addTemplateIndicator(payload);
    },

    addTemplateIndicator(form) {
      this.add_template_indicator(form).then(({ status }) => {
        if (![200, 201].includes(status)) {
          return;
        }
        this.$emit("close_modal_template");
      });
    },

    editTemplateIndicator(form) {
      this.edit_template_indicator({ ...form, id: this.template.id }).then(
        ({ status }) => {
          if (![200, 201].includes(status)) {
            return;
          }
          this.$emit("close_modal_template");
        }
      );
    },

    handleSaveTemplate() {
      this.$v.template.$touch();
      if (!this.$v.$anyError && !this.$v.$anyError) {
        this.handleSave(this.template);
      }
    },

    removeUsedsIndicators() {
      if (this.templates?.length) {
        let templates = [...this.templates];
        templates = this.templates.map(({ indicator }) => indicator);
        this.indicatorsOptions =
          this.indicatorsOptions.filter(
            (indicator) => !templates.includes(indicator)
          ) ?? [];
      }
    },

    prepareTemplate(template) {
      const { ranges } = template;

      template.ranges = ranges;
    },

    changedRanges(ranges) {
      this.template.ranges = ranges;
      this.handleUpdateTemplates(this.template);
    },

    handleUpdateTemplates(template) {
      template.ranges = template.ranges.map(({ value, min, max }) => {
        const minNum = Number(min);
        const maxNum = Number(max);

        return {
          value,
          min: minNum || minNum === 0 ? minNum : null,
          max: maxNum ? maxNum.toFixed(2) : null,
        };
      });
      this.prepareTemplate(template);
    },

    intervalItems(template) {
      const { emptyRanges } = template;
      const minV = 0;
      const maxV = 10;
      const items = emptyRanges > 0 ? emptyRanges - 1 : 1;
      const increments = (maxV - minV) / items;

      let intervalItem = [...Array(items + 1)].map((x, y) => {
        if (y === 0) {
          return 0;
        }

        if (y === emptyRanges - 1) {
          return 10;
        }

        return (minV + increments * y)?.toFixed(2);
      });

      if (intervalItem.length === 1 && isNaN(intervalItem[0])) {
        intervalItem = [0];
      }

      if (template.isDecrescent) {
        intervalItem.reverse();
      }
      return intervalItem;
    },

    generateArrMinMax(template) {
      const values = [];

      this.intervalItems(template).forEach((value, index) => {
        const minValue = Number(template?.minValue);
        const min = values[index - 1]
          ? Number(values[index - 1].max)
          : minValue;
        const max = Number(min) + Number(template.interval);

        values.push({
          value,
          min,
          max,
        });
      });
      template.ranges = values;

      this.handleUpdateTemplates(template);
    },

    handleChangeConfigRange(template) {
      this.generateArrMinMax(template);
    },

    handleAddRanges(template) {
      this.handleChangeConfigRange(template);
    },

    calcWeightIndicators(list) {
      const totalWeight = list.reduce((n, { weight }) => n + weight, 0);
      const currentWeight = this.template.weight;
      this.maxWeight = 10 - (totalWeight - currentWeight);
    },

    getEdit() {
      this.get_template_indicator({ id: this.selected_id }).then(({ data }) => {
        const template = {
          ...data,
          weight: data.weight,
          indicator: {
            ...data.indicator,
            type: this.typeOptions.find((e) => e.id === data.indicator.type),
            result_source: this.sourceOptions.find((e) => e.argument === data.indicator.argument.argument),
          },
        };
        this.template = template;
        this.handleSelectResultSource(template.indicator.argument);
        this.template.weight = data.weight;
        this.template.emptyRanges = data.ranges?.length;
        const firstRange = data.ranges[0];
        this.template.isDecrescent = firstRange.value === 10;
        this.template.minValue = firstRange.min;
        this.template.interval = firstRange.max - firstRange.min;

        const lisTemplateIndicators = this.template_indicators?.results;
        this.calcWeightIndicators(lisTemplateIndicators);
      });
    },

    getResultSources() {
      const { code: sub_module } = this.selected_submodule;
      api.get("/argument-result-source/", { sub_module }).then(({ data }) => {
        this.sourceOptions = data;
      });
    },

    handleSelectResultSource(resultSource) {
      const { types } = resultSource;
      this.typeOptions = this.typeOptions.filter((type) => {
        return this.typeOptions.some(() => types.includes(type.id));
      });
    },
  },

  mounted() {
    this.template_id = this.$route.params.id;
    const lisTemplateIndicators = this.template_indicators?.results;
    if (lisTemplateIndicators && this.is_add) {
      this.calcWeightIndicators(lisTemplateIndicators);
    }
    if (!this.is_add) {
      this.getEdit();
    } else {
      this.template = {
        interval: 1,
        minValue: 0,
        weight: 0,
        emptyRanges: 2,
        ranges: [],
        sourceOptions: [],
        indicator: {
          name: "",
          result_source: {},
          type: {},
        },
      };
      this.handleAddRanges(this.template);
    }

    this.getResultSources();
  },
};
</script>
<style lang="scss">
.authomatization {
  border: 1px solid $primary;
  border-radius: $radius;
  padding: 12px;
  margin-bottom: 12px;
}
</style>
